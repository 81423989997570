@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.actv_block {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease, transform 0.6s ease;
  animation: fadeInUp 0.6s forwards;
  width: 100%;
  height: calc(100%);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  z-index: 100001;

  .container {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; /* Обеспечивает выравнивание по верху */
    justify-content: flex-start;
    gap: 0px; /* Устанавливаем промежуток между блоками */


    @media screen and (min-width: 900px){
      .btn {
        flex: 1 1 23%; /* Задаем фиксированную ширину с учетом пробелов между элементами */
        min-width: 200px;
        height: 200px;
        padding: 50px 30px 20px 50px;
        border: 1px solid #FFF;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 26pt;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center; /* Центрирование текста внутри кнопки */
      }
    }
    @media screen and (max-width: 900px){
      .btn {
        flex: 1 1 23%; /* Задаем фиксированную ширину с учетом пробелов между элементами */
        min-width: 200px;
        height: 200px;
        padding: 30px 30px 20px 30px;
        border: 1px solid #FFF;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 16pt;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center; /* Центрирование текста внутри кнопки */
      }
    }

    .btn:hover {
      background-color: rgba(65, 0, 153, 0.8);
    }
  }
}