.main{
  z-index: 1111111111;
  width: 350px;
  min-height: 150px;
  background-color: #410099;
  color: #F1F1F1;
  position: fixed;
  top: 10px;
  right: 10px;
  display: none;
}