@media screen and (min-width: 900px){
  .bodymain {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    text-decoration: none;

    .listpersonal{
      width: 100%;
      max-width: 1920px;

      .title{
        width: calc(100% - 60px);
        padding: 0 30px;
        max-width: 1920px;
        //font-family: 'ALS', sans-serif;
        font-weight: 600;
        font-size: 60px;
        text-transform: uppercase;
        color: #454545;
        margin-bottom: 50px;
        margin-top: 100px;
      }
      .list{
        width: calc(100% - 60px);
        padding: 0 30px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 200px;
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(50px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .blockman{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          animation: fadeInUp 0.6s forwards;
          //opacity: 0;
          //transform: translateY(50px);
          //transition: opacity 0.6s ease, transform 0.6s ease;
          margin: 0 4px;
          //&.visible {
          //  opacity: 1;
          //  transform: translateY(0);
          //}
          opacity: 0;
          transform: translateY(50px);
          transition: opacity 0.6s ease, transform 0.6s ease;

          /* Анимация появления блока */

          .photo{
            width: calc(1920px / 4 - 50px);
            height: calc(1920px / 3 - 50px);
            max-width: 300px;
            max-height: 400px;
            background-image: url("../imgs/avatar.png");
            background-size: cover;
            background-position: center center;
            margin: 30px 30px 5px 30px;
          }
          .fio{
            width: calc(1920px / 4 - 50px);
            max-width: 300px;
            .name{
              font-weight: 600;
            }
          }
          .dev{
            width: calc(1920px / 4 - 50px);
            max-width: 300px;
          }
          .btncontact{
            width: calc(1920px / 4 - 50px);
            max-width: 300px;
            padding: 20px 0;
            margin-top: 20px;
            border: 1px solid #454545;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s ease-in-out;
            cursor: pointer;
          }
          .btncontact:hover{
            background-color: rgba(72,20,160,1);
            border: 1px solid rgba(72,20,160,1);
            transition: 0.3s ease-in-out;
            color: #FFFFFF;
          }
        }

      }
    }
    .contacts{
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;

      .ontheleft{
        width: calc(50%);
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #410099;
      }
      .ontheright{
        width: calc(50%);
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #410099;

        .entry{
          display: flex;
          flex-direction: column;

          .btn{
            padding: 20px 50px;
            border: 1px solid #FFF;
            margin: 10px 0;
            font-size: 20pt;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s;
            cursor: pointer;
            color: #FFFFFF;
          }
          .btn:hover{
            color: #410099;
            background-color: #FFFFFF;
            transition: 0.3s;

          }
        }

      }


    }

    .container50{
      width: calc(100% - 140px);
      max-width: calc(1920px / 2 - 140px);
      color: #FFF;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 70px;
      .strock{
        font-size: 30px;
      }
    }
  }
}
@media screen and (max-width: 900px){
  .bodymain {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    text-decoration: none;

    .listpersonal{
      width: 100%;
      max-width: 1920px;

      .title{
        width: calc(100% - 60px);
        padding: 0 30px;
        max-width: 1920px;
        //font-family: 'ALS', sans-serif;
        font-weight: 600;
        font-size: 60px;
        text-transform: uppercase;
        color: #454545;
        margin-bottom: 50px;
        margin-top: 100px;
      }
      .list{
        width: calc(100% - 60px);
        padding: 0 25px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 200px;
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(50px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .blockman{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          animation: fadeInUp 0.6s forwards;
          //opacity: 0;
          //transform: translateY(50px);
          //transition: opacity 0.6s ease, transform 0.6s ease;
          margin: 0 4px;
          //&.visible {
          //  opacity: 1;
          //  transform: translateY(0);
          //}
          opacity: 0;
          transform: translateY(50px);
          transition: opacity 0.6s ease, transform 0.6s ease;

          /* Анимация появления блока */

          .photo{
            width: calc(1920px / 4 - 50px);
            height: calc(1920px / 3 - 50px);
            max-width: 300px;
            max-height: 400px;
            background-image: url("../imgs/avatar.png");
            background-size: cover;
            background-position: center center;
            margin: 30px 30px 5px 30px;
          }
          .fio{
            width: calc(1920px / 4 - 50px);
            max-width: 300px;
            .name{
              font-weight: 600;
            }
          }
          .dev{
            width: calc(1920px / 4 - 50px);
            max-width: 300px;
          }
          .btncontact{
            width: calc(1920px / 4 - 50px);
            max-width: 300px;
            padding: 20px 0;
            margin-top: 20px;
            border: 1px solid #454545;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s ease-in-out;
            cursor: pointer;
          }
          .btncontact:hover{
            background-color: rgba(72,20,160,1);
            border: 1px solid rgba(72,20,160,1);
            transition: 0.3s ease-in-out;
            color: #FFFFFF;
          }
        }

      }
    }
    .contacts{
      width: 100%;
      //height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      margin-top: 100px;
      .ontheleft{
        width: calc(100%);
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #410099;
        font-size: 12px;
      }
      .ontheright{
        width: calc(100%);
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        background-color: #410099;

        .entry{
          display: flex;
          flex-direction: column;

          .btn{
            padding: 30px 50px;
            border: 1px solid #FFF;
            margin: 10px 0;
            font-size: 14pt;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.3s;
            cursor: pointer;
            color: #FFFFFF;
          }
          .btn:hover{
            color: #410099;
            background-color: #FFFFFF;
            transition: 0.3s;

          }
        }
      }


    }

    .container50{
      width: calc(100% - 140px);
      max-width: calc(1920px / 2 - 140px);
      color: #FFF;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px 70px;
      .strock{
        font-size: 20px;
      }
    }
  }
}


