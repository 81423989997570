@media screen and (min-width: 1600px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    //padding-top: 100px;

    .container{
      width: 100%;
      min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./paralax2.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          width: 50%;
          img{
            width: 70%;
            animation: moveLeftRight 3s ease-in-out infinite;
            margin-left: 50px;
          }
          @keyframes moveLeftRight {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(10px) rotate(2deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
        }

        .right{
          width: calc(50% - 60px);
          padding: 60px 60px 60px 0;
          .title{
            font-family: 'ALS';
            font-size: 60pt;
            text-transform: uppercase;
            margin-bottom: 100px;
            color: #410099;
          }
          .pretitle{
            font-size: 40pt;
            text-transform: uppercase;
            color: #595959;
          }
          .desc{

            font-size: 20pt;
            color: #454545;
          }
        }
      }
      .contenttwo{
        width: 100%;
        max-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .blocks{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          .why{
            width: calc(100% / 3 - 60px);
            max-height: 50%;
            padding: 20px;
            border: 1px solid #CCC;
            min-height: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .imgblock{
              width: calc(100%);
              min-height: 250px;
              height: 50%;
              background-size: cover;
              background-position: center center;
            }
            .desc{
              width: 100%;
              height: 50%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;
              transition: 0.3s;
              .title{

                //height: 100%;
                font-size: 20pt;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                color: #454545;
                text-align: end;
                width: calc(100%);
                font-weight: 600;
                text-transform: uppercase;
                transition: 0.3s;

              }
              .description{
                display: flex;
                width: calc(100%);
                transition: 0.3s;
              }
            }
            .desc:hover{

            }
          }
        }

      }

    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }
    @media (max-width: 1200px) {
      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./paralax2.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          .blocks{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .why{
              width: calc(100% / 2 - 32px);
              height: calc(33.33% - 32px);
              //border: 1px solid #CCC;
              background-size: cover;
              margin: 5px;

              background-position: center center;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 3px 3px 10px rgba(0,0,0,0.5);
              .desc{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-end;
                transition: 0.3s;
                .title{

                  height: calc(50% - 40px);
                  font-size: 20pt;
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-start;
                  color: #FFF;
                  text-align: end;
                  width: calc(70% - 40px);
                  padding: 20px;
                  font-weight: 600;
                  text-transform: uppercase;
                  text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.7);
                  transition: 0.3s;

                }
                .description{
                  display: flex;
                  width: calc(100% - 40px);
                  height: calc(50% - 40px);
                  padding: 20px;
                  background-color: rgba(255,255,255,0.8);
                  font-size: 14pt;
                  transition: 0.3s;
                }
              }
            }
          }

        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    //padding-top: 100px;

    .container{
      width: 100%;
      min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./paralax2.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          width: 50%;
          img{
            width: 70%;
            animation: moveLeftRight 3s ease-in-out infinite;
            margin-left: 50px;
          }
          @keyframes moveLeftRight {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(10px) rotate(2deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
        }

        .right{
          width: calc(50% - 60px);
          padding: 60px 60px 60px 0;
          .title{
            font-family: 'ALS';
            font-size: 40pt;
            text-transform: uppercase;
            margin-bottom: 40px;
            color: #410099;
          }
          .pretitle{
            font-size: 20pt;
            text-transform: uppercase;
            color: #595959;
          }
          .desc{

            font-size: 14pt;
            color: #454545;
          }
        }
      }
      .contenttwo{
        width: 100%;
        max-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .blocks{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          flex-wrap: wrap;
          .why{
            width: calc(100% / 3 - 60px);
            max-height: 50%;
            padding: 20px;
            border: 1px solid #CCC;
            min-height: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .imgblock{
              width: calc(100%);
              min-height: 250px;
              height: 50%;
              background-size: cover;
              background-position: center center;
            }
            .desc{
              width: 100%;
              height: 50%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;
              transition: 0.3s;
              .title{

                //height: 100%;
                font-size: 20pt;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                color: #454545;
                text-align: end;
                width: calc(100%);
                font-weight: 600;
                text-transform: uppercase;
                transition: 0.3s;

              }
              .description{
                display: flex;
                width: calc(100%);
                transition: 0.3s;
              }
            }
            .desc:hover{

            }
          }
        }

      }

    }
  }
}
@media screen and (min-width: 650px) and (max-width: 1200px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    //padding-top: 100px;


      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./paralax2.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 40px;
          .blocks{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            .why{
              width: calc(100% / 2 - 80px);
              max-height: 50%;
              padding: 20px;
              border: 1px solid #CCC;
              min-height: 450px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              margin: 10px;
              .imgblock{
                width: calc(100%);
                min-height: 250px;
                height: 50%;
                background-size: cover;
                background-position: center center;
              }
              .desc{
                width: 100%;
                height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                transition: 0.3s;
                .title{

                  //height: 100%;
                  font-size: 20pt;
                  display: flex;
                  justify-content: center;
                  align-items: flex-start;
                  color: #454545;
                  text-align: end;
                  width: calc(100%);
                  font-weight: 600;
                  text-transform: uppercase;
                  transition: 0.3s;

                }
                .description{
                  display: flex;
                  width: calc(100%);
                  transition: 0.3s;
                }
              }
              .desc:hover{

              }
            }
          }

        }
      }
  }
}
@media screen and (max-width: 650px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    //padding-top: 100px;


    .container {
      width: 100%;
      min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./paralax2.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .left{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 40%;
            animation: moveLeftRight 3s ease-in-out infinite;
            margin-left: 50px;
          }
          @keyframes moveLeftRight {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(10px) rotate(2deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
        }

        .right{
          width: calc(100% - 120px);
          padding: 60px 60px 60px 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: -150px;
          z-index: 1;
          .title{
            font-family: 'ALS';
            font-size: 30pt;
            text-transform: uppercase;
            margin-bottom: 10px;
            color: #410099;
          }
          .pretitle{
            font-size: 20pt;
            text-transform: uppercase;
            color: #595959;
            text-align: center;
          }
          .desc{
margin-top: 20px;
            font-size: 14pt;
            color: #454545;
            text-align: justify;
          }
        }
      }
      .contenttwo{
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;
        .blocks{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          .why{
            width: calc(100% / 1 - 80px);
            max-height: 50%;
            padding: 20px;
            border: 1px solid #CCC;
            min-height: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin: 10px;
            .imgblock{
              width: calc(100%);
              min-height: 250px;
              height: 50%;
              background-size: cover;
              background-position: center center;
            }
            .desc{
              width: 100%;
              height: 50%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;
              transition: 0.3s;
              .title{

                //height: 100%;
                font-size: 20pt;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                color: #454545;
                text-align: end;
                width: calc(100%);
                font-weight: 600;
                text-transform: uppercase;
                transition: 0.3s;

              }
              .description{
                display: flex;
                width: calc(100%);
                transition: 0.3s;
              }
            }
            .desc:hover{

            }
          }
        }

      }
    }
  }
}